.github.ribbon {
	position: fixed;
	display: block;
	top: 40px;
	right: 0;
	border: 0;
	z-index: 1001;
}

.prettyprint {
	padding: 10px!important;
}

#wrap {
	padding-top: 70px;
}

#home h1 {
	margin-bottom: 30px;
}
#demo,
#examples,
#support,
#download {
	padding-top: 40px;
}
